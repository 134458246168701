import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import Login from "@/modules/auth/components/login";
import ForgotPassword from "@/modules/auth/components/forget";
import ResetPasswordForm from "@/modules/auth/components/reset";
import SignIn from "@/modules/timemangement/signin";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/products/request",
    redirect: "/products",
  },
  {
    path: "",
    component: {
      render(c) {
        return c("router-view");
      },
    },

    children: [
      {
        path: "home",
        component: () => import("../modules/home/index"),
        children: [
          {
            path: "",
            name: "landingPage",
            component: () => import("../modules/home/components/landing-page"),
          },
        ],
      },
      {
        path: "products",
        component: () => import("../modules/Products/index"),
        children: [
          {
            path: "",
            name: "allProductsReport",
            component: () => import("../modules/Products/components/Projects"),
          },
          {
            path: "request",
            name: "all-requests-product",
            component: () => import("../modules/all-requests/index"),
            children: [
              {
                path: "purchasing/:id",
                name: "PurchasingAllRequests",
                meta: {
                  isProductPage: true,
                  isRequest: true,
                },
                component: () =>
                  import("../modules/all-requests/components/purchasing"),
              },
              {
                path: "printing/:id",
                name: "printingAllRequests",
                meta: {
                  isProductPage: true,
                  isRequest: true,
                },
                component: () =>
                  import("../modules/all-requests/components/printing"),
              },
              {
                path: "production/:id",
                name: "productionAllRequests",
                meta: {
                  isProductPage: true,
                  isRequest: true,
                },
                component: () =>
                  import("../modules/all-requests/components/production"),
              },
              {
                path: "photography/:id",
                name: "photographyAllRequests",
                meta: {
                  isProductPage: true,
                  isRequest: true,
                },
                component: () =>
                  import("../modules/all-requests/components/photography"),
              },
              {
                path: "extras/:id",
                name: "extrasAllRequests",
                meta: {
                  isProductPage: true,
                  isRequest: true,
                },
                component: () =>
                  import("../modules/all-requests/components/extras"),
              },
              {
                path: "request-process/:id",
                name: "request-process",
                component: () =>
                  import("../modules/all-requests/components/request-process"),
              },
            ],
          },
          {
            path: "job-orders/:id",
            name: "jobOrdersRequests",
            meta: {
              isProductPage: true,
              isJobOrder: true,
            },
            component: () =>
              import("../modules/job-orders/components/job-orders"),
          },

          {
            path: "job-orders-process/:id",
            name: "jobOrdersProcess",
            component: () =>
              import("../modules/job-orders/components/job-order-process"),
          },
        ],
      },
      {
        path: "all-job-orders",
        name: "AlljobOrdersRequests",

        component: () => import("../modules/all-job-orders"),
      },
      {
        path: "all-job-orders/:status?",
        name: "jobOrdersProcessWtihStatus",
        component: () => import("../modules/all-job-orders"),
      },
      {
        path: "all-job-orders/sales/:status?",
        name: "jobOrdersProcessSalesWtihStatus",
        component: () => import("../modules/all-job-orders"),
      },
      {
        path: "timemangement",
        component: () => import("../modules/timemangement/index"),
        children: [
          {
            path: "",
            name: "TMPages",
            component: () => import("../modules/timemangement/TMpages"),
          },
          {
            path: "overview",
            name: "adminOverview",
            component: () => import("../modules/timemangement/overview"),
          },
          {
            path: "signin",
            name: "TMsignin",
            component: () => import("../modules/timemangement/signin"),
          },
          {
            path: "myattendance",
            name: "adminMyattendance",
            component: () => import("../modules/timemangement/myattendance"),
          },
          {
            path: "usersattendance",
            name: "usersAttendance",
            component: () => import("../modules/timemangement/usersattendance"),
          },
          {
            path: "projectattendance",
            name: "projectAttendance",
            component: () =>
              import("../modules/timemangement/projectattendance"),
          },
          {
            path: "projectattendance/project_id=:project_id&task_id=:task_id",
            name: "projectAttendancebyID",
            component: () =>
              import("../modules/timemangement/projectattendance"),
          },
          {
            path: "tasks",
            name: "addTasks",
            component: () => import("../modules/timemangement/tasks"),
          },
          {
            path: "tags",
            name: "addTags",
            component: () => import("../modules/timemangement/tags"),
          },
          {
            path: "working",
            name: "working",
            component: () => import("../modules/timemangement/working"),
          },
          {
            path: "timesheets",
            name: "timesheets",
            component: () => import("../modules/timemangement/timesheets"),
          },
          {
            path: "taskstatus",
            name: "taskstatus",
            component: () => import("../modules/timemangement/taskStatus"),
          },
          {
            path: "attendancepages",
            name: "attendancepages",
            component: () => import("../modules/timemangement/attendancePages"),
          },
          {
            path: "taskdetails",
            name: "taskdetails",
            component: () => import("../modules/timemangement/taskdetails"),
          },
          {
            path: "export",
            name: "exportFiles",
            component: () => import("../modules/timemangement/export"),
          },
          {
            path: "attendancePerDay",
            name: "userAttendancePerDay",
            component: () =>
              import("../modules/timemangement/attendancePerDay"),
          },
          {
            path: "reports",
            name: "Reports",
            component: () => import("../modules/timemangement/reports"),
          },
          {
            path: "categories",
            name: "categories",
            component: () => import("../modules/timemangement/categories"),
          },
          {
            path: "projectsincategory/:id",
            name: "projectsincategory",
            component: () =>
              import("../modules/timemangement/projectsincategory"),
          },
          {
            path: "projectDetails/:id",
            name: "projectDetails",
            component: () => import("../modules/timemangement/projectDetails"),
          },
          {
            path: "dragtest",
            name: "dragtest",
            component: () => import("../modules/timemangement/dragtest"),
          },
          {
            path: "countryProjects/:id",
            name: "countryProjects",
            component: () => import("../modules/timemangement/countryProjects"),
          },
          {
            path: "projectTasks/:id",
            name: "projectTasks",
            component: () => import("../modules/timemangement/projectTasks"),
          },
        ],
      },

      {
        path: "settings",
        component: () => import("../modules/Setting/index"),

        children: [
          {
            path: "",
            name: "settingProducts",
            component: () =>
              import("../modules/Setting/components/all-setting"),
          },

          {
            path: "departments",
            name: "settingDepartments",
            component: () =>
              import("../modules/Setting/components/departments"),
            beforeEnter(to, from, next) {
              store.getters.user.role_id == 1
                ? next()
                : next({ name: "notauth" });
            },
          },
          {
            path: "department/:id",
            name: "settingDepartmentsSub",
            component: () =>
              import("../modules/Setting/components/departments-sub"),
            beforeEnter(to, from, next) {
              store.getters.user.role_id == 1
                ? next()
                : next({ name: "notauth" });
            },
          },
          {
            path: "teams",
            name: "settingTeams",
            component: () => import("../modules/Setting/components/teams"),
            beforeEnter(to, from, next) {
              store.getters.user.role_id == 1
                ? next()
                : next({ name: "notauth" });
            },
          },
          {
            path: "teams/:id",
            name: "settingTeamsMembers",
            component: () =>
              import("../modules/Setting/components/teams-members"),
            beforeEnter(to, from, next) {
              store.getters.user.role_id == 1
                ? next()
                : next({ name: "notauth" });
            },
          },
          {
            path: "users",
            name: "settingUsers",
            component: () => import("../modules/Setting/components/users"),
            beforeEnter(to, from, next) {
              store.getters.user.role_id == 1
                ? next()
                : next({ name: "notauth" });
            },
          },
          {
            path: "roles",
            name: "settingRoles",
            component: () => import("../modules/Setting/components/roles"),
            beforeEnter(to, from, next) {
              store.getters.user.role_id == 1
                ? next()
                : next({ name: "notauth" });
            },
          },
          {
            path: "companies",
            name: "settingCompanies",
            component: () => import("../modules/Setting/components/companies"),
          },
          {
            path: "notification",
            name: "settingNotification",
            component: () =>
              import("../modules/Setting/components/notifications"),
          },
          {
            path: "activity-logs",
            name: "settingActivityLogs",
            component: () =>
              import("../modules/Setting/components/activity-logs"),
          },
          {
            path: "permissions",
            name: "permissions",
            component: () =>
              import("../modules/Setting/components/permissions"),
          },

          {
            path: "request-type",
            name: "settingRequestType",
            component: () =>
              import("../modules/Setting/components/requestType"),
            beforeEnter(to, from, next) {
              store.getters.user.role_id == 1
                ? next()
                : next({ name: "notauth" });
            },
          },
          {
            path: "currency",
            name: "settingCurrency",
            component: () => import("../modules/Setting/components/currency"),
            beforeEnter(to, from, next) {
              store.getters.user.role_id == 1
                ? next()
                : next({ name: "notauth" });
            },
          },
          {
            path: "countries",
            name: "settingCountries",
            component: () => import("../modules/Setting/components/countries"),
            beforeEnter(to, from, next) {
              store.getters.user.role_id == 1
                ? next()
                : next({ name: "notauth" });
            },
          },
        ],
      },
      {
        path: "/changePass",
        name: "changePass",
        component: () => import("../modules/auth/components/changePass"),
      },
      {
        path: "/editProfile",
        name: "editProfile",
        component: () => import("../modules/auth/components/editProfile"),
      },
      {
        path: "/login",
        name: "auth",
        component: Login,
        meta: {
          noLayout: true,
          authPage: true,
        },
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: ForgotPassword,
        meta: {
          noLayout: true,
          authPage: true,
        },
      },
      {
        path: "/reset-password/:token",
        name: "reset-password-form",
        component: ResetPasswordForm,
        meta: {
          noLayout: true,
          authPage: true,
        },
      },
      {
        path: "/notauth",
        name: "notauth",
        component: () => import("../modules/notauth/index.vue"),
        meta: {
          noLayout: true,
        },
      },
      {
        path: "*",
        component: () => import("../modules/notfound/index.vue"),
        meta: {
          noLayout: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",

  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;

router.beforeEach((to, from, next) => {
  if (!to.meta.authPage && !store.getters.token) next({ name: "auth" });
  if (to.meta.authPage && store.getters.token)
    next({ name: "allProductsReport" });
  else next();
});
router.beforeEach((to, from, next) => {
  // Check if the route is leaving the page with the counter
  if (from.name === "TMsignin") {
    // Save the counter state to local storage
    localStorage.setItem("isCounterRunning", SignIn.isCounterRunning);
  }
  next();
});
