var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topbar"},[_c('v-app-bar',{staticClass:"ConatinerOfHeader",attrs:{"color":"white"}},[_c('v-btn',{staticClass:"hidden-lg-and-up",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('togglenav')}}},[_c('img',{attrs:{"src":require("@/assets/img/menu_icon.png"),"alt":"menu icon"}})]),(_vm.metaProduct.isProductPage)?_c('div',{staticClass:"conRequestLinks"},[_c('router-link',{class:_vm.metaProduct.isRequest ? 'activeItem' : '',attrs:{"to":{
          name: 'PurchasingAllRequests',
          parms: { id: _vm.$route.params.id },
        }}},[_vm._v(" All Requests ")]),_c('router-link',{class:_vm.metaProduct.isJobOrder ? 'activeItem' : '',attrs:{"to":{ name: 'jobOrdersRequests', parms: { id: _vm.$route.params.id } }}},[_vm._v(" Job Orders ")])],1):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"content-class":"conOfNotificationMenu","offset-y":"","rounded":"0","max-width":"300","left":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bellBtnContainer",attrs:{"icon":"","ripple":false},on:{"click":function($event){return _vm.inputOfNotification()}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"#2C77FF","content":_vm.notificationNumber,"value":_vm.notificationNumber,"overlap":""}},[_c('v-icon',[_vm._v("mdi-bell-outline")])],1)],1)]}}])},[_c('v-card',{staticClass:"mx-auto conOfNotificationCard",attrs:{"max-width":"400"}},[_c('v-toolbar',{staticClass:"d-flex justify-space-between w-100 align-center"},[_c('v-toolbar-title',[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Notification")])])],1),_c('v-list',{staticClass:"conOfListData scrollbar"},[(_vm.listData && _vm.listData.length > 0)?[_vm._l((_vm.listData),function(item){return _c('v-list-item',{key:item.id,class:item.read_at == null ? 'unread' : '',on:{"click":function($event){return _vm.markAsRead(item.id)}}},[_c('router-link',{attrs:{"to":{
                  name:
                    item.data.action_type == 'jo'
                      ? 'jobOrdersProcess'
                      : item.data.action_type == 'comment'
                      ? 'jobOrdersProcess'
                      : item.data.action_type == 'project'
                      ? 'PurchasingAllRequests'
                      : item.data.action_type == 'cost'
                      ? 'request-process'
                      : item.data.action_type == 'request'
                      ? 'request-process'
                      : item.data.action_type == 'jo_all'
                      ? 'jobOrdersProcessWtihStatus'
                      : item.data.action_type == 'sales_jo_all'
                      ? 'jobOrdersProcessSalesWtihStatus'
                      : item.data.action_type == 'sales_jo'
                      ? 'jobOrdersProcess'
                      : item.data.action_type == 'company'
                      ? 'settingCompanies'
                      : '',
                  params: { id: item.data.action_id },
                  query: {
                    status:
                      item.data.action_type == 'sales_jo_all' ? '1' : '8', // Set status based on condition
                  },
                }}},[_c('div',{staticClass:"conOfNotif"},[_c('div',{staticClass:"messageNotification"},[_c('span',[_c('v-icon',{class:item.data.action === true ? 'red-icon' : 'blue-icon'},[_vm._v("mdi-bell-outline")])],1),_vm._v(" "+_vm._s(item.data.message)+" ")]),_c('div',{staticClass:"timeOfNotification"},[_vm._v(" "+_vm._s(item.created_at)+" ")])])])],1)}),(_vm.listData.length)?_c('infinite-loading',{attrs:{"spinner":"spiral"},on:{"infinite":_vm.infiniteScroll}},[_c('div',{staticClass:"noMoreNot",attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v("No more Notification")]),_c('div',{staticClass:"noMoreNot",attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v(" No more Notification ")])]):_vm._e()]:(_vm.isLoadingNotification)?_c('div',{staticClass:"loadedNotification"},[_c('div',[_c('div',{staticClass:"loader"},[_c('v-progress-circular',{attrs:{"size":40,"width":3,"indeterminate":"","color":"primary"}})],1),_c('div',{staticClass:"loaderNotification"},[_vm._v("Notifications Are Loaded")])])]):_vm._e()],2)],1),_c('v-divider')],1),_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","offset-y":"","left":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"conUserbtn",attrs:{"ripple":false,"text":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"conOfuserControl d-flex align-center"},[_c('div',{staticClass:"conOfUserInfo mr-2"},[_c('div',{staticClass:"userName"},[_vm._v(_vm._s(_vm.user.name))]),_c('div',{staticClass:"userTitle"},[_vm._v(_vm._s(_vm.user.bio))])]),_c('div',{staticClass:"conUserIcon"},[_c('img',{attrs:{"src":_vm.user.avatar,"alt":""}})])]),_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"conOfListUser",on:{"click":function($event){return _vm.$router.push({ name: 'editProfile' })}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"iconStyle"},[_vm._v("mdi-account-edit")])],1),_c('v-list-item-title',[_vm._v("Edit Profile")])],1),_c('v-list-item',{staticClass:"conOfListUser",on:{"click":function($event){return _vm.$router.push({ name: 'changePass' })}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"iconStyle"},[_vm._v("mdi-lock")])],1),_c('v-list-item-title',[_vm._v("Change Password")])],1),_c('v-list-item',{staticClass:"conOfListUser",on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"iconStyle"},[_vm._v("mdi-logout")])],1),_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }