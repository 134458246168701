import Vue from "vue";
import Vuex from "vuex";
// import {ApiService} from "@/modules/shared/services/api";
// let apiServices = new ApiService();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isCounterRunning: false,
    isRefresh: false,
    apiMessage: "",
    rejectReason: null,
    paymentPercentage: null,
    rejectReasonCeo: null,
    createdByUser: "",
    projectCountry: "",
    projectName: "",
    _totalRequests: null,
    PoNo: null,
    assignTo: null,
    po_number: null,
    token: localStorage.getItem("access_token") || null,
    user: JSON.parse(localStorage.getItem("user")) || null,
    expires_at: localStorage.getItem("expires_at") || null,
    dialog: false,
  },
  getters: {
    isCounterRunning: (state) => state.isCounterRunning,
    totalRequests(state) {
      return state._totalRequests;
    },
    createdByUser(state) {
      return state.createdByUser;
    },
    assignTo(state) {
      return state.assignTo;
    },
    projectCountry(state) {
      return state.projectCountry;
    },
    projectName(state) {
      return state.projectName;
    },

    PoNo(state) {
      return state.PoNo;
    },
    po_number(state) {
      return state.po_number;
    },

    rejectReason(state) {
      return state.rejectReason;
    },
    paymentPercentage(state) {
      return state.paymentPercentage;
    },
    rejectReasonCeo(state) {
      return state.rejectReasonCeo;
    },
    apiMessage(state) {
      return state.apiMessage;
    },
    dialog(state) {
      return state.dialog;
    },
    isRefresh(state) {
      return state.isRefresh;
    },
    token(state) {
      return state.token;
    },
    user(state) {
      return state.user;
    },
    expires_at(state) {
      return state.expires_at;
    },
  },
  mutations: {
    setCounterRunning(state, value) {
      state.isCounterRunning = value;
    },

    setApiMessage(state, message) {
      state.apiMessage = message;
    },
    setPoNo(state, value) {
      state.PoNo = value;
    },
    setpo_number(state, message) {
      state.po_number = message;
    },
    setTotalRequests(state, total) {
      state._totalRequests = total;
    },
    setCreatedByUser(state, data) {
      state.createdByUser = data;
    },
    setAssignTo(state, data) {
      state.assignTo = data;
    },
    setProjectCountry(state, data) {
      state.projectCountry = data;
    },
    setProjectName(state, data) {
      state.projectName = data;
    },

    setRejectReason(state, reason) {
      state.rejectReason = reason;
    },
    setpaymentPercentage(state, reason) {
      state.paymentPercentagen = reason;
    },
    setRejectReasonCeo(state, reason) {
      state.rejectReasonCeo = reason;
    },
    UPDATE_DIALOG(state, value) {
      state.dialog = value;
    },
    UPDATE_ISREFRESH(state, value) {
      state.isRefresh = value;
    },
    setToken(state, value) {
      if (!value) localStorage.removeItem("access_token");
      else localStorage.setItem("access_token", value);
      state.token = value;
    },
    setExpires_at(state, value) {
      if (!value) localStorage.removeItem("expires_at");
      else localStorage.setItem("expires_at", value);
      state.expires_at = value;
    },
    setUser(state, value) {
      if (!value) localStorage.removeItem("user");
      else localStorage.setItem("user", JSON.stringify(value));
      state.user = value;
    },
  },
  actions: {
    refreshTokens() {
      // Do whatever you need to do to exchange refresh token for access token
      // Finally, call autoRefresh to set up the new timeout
    },
    // getTotalRequests({ context }){
    //   apiServices
    //       .get(`projects/${this.$route.params.id}`)
    //       .then((res) => {
    //         if (res) {
    //           context.commit('setTotalRequests', res)
    //         }
    //       });
    // }
  },
  modules: {},
});
